// const wx = window.wx;
import { GetDatadictionary, GetDataChildOptions, GetCustomerOptions, GetProjectOptions, GetUser } from "@/api";

/* 加载列表页 */
export const loadListAction = function (vue, action, dicts, callback) {
    vue.loading = true;
    var params = { userToken: { userId: vue.$store.state.user.userId } };
    Object.keys(dicts).forEach(c => {
        params[c] = dicts[c];
    });
    action(params).then((res) => {
        vue.loading = false;
        if (res.code === 0) {
            callback(res.data);
        } else {
            console.log(res);
        }
    }).catch((err) => {
        vue.loading = false;
        console.log(err);
    });
};

/* 加载详情页 */
export const loadDetailAction = function (vue, action, dicts, callback) {
    let toast = vue.$createToast({ txt: "正在加载...", mask: true, time: 0, });
    toast.show();
    var params = { userToken: { userId: vue.$store.state.user.userId } };
    Object.keys(dicts).forEach(c => {
        params[c] = dicts[c];
    });
    action(params).then((res) => {
        toast.hide();
        if (res.code === 0) {
            callback(res.data);
        } else {
            toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
            toast.show();
        }
    }).catch((err) => {
        toast.hide();
        toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
        toast.show();
    });
};

/* 加载数据 */
export const loadDataAction = function (vue, action, dicts, callback) {
    var params = { userToken: { userId: vue.$store.state.user.userId } };
    Object.keys(dicts).forEach(c => {
        params[c] = dicts[c];
    });
    action(params).then((res) => {
        if (res.code === 0) {
            callback(res.data);
        }
    });
};

/* 保存/提交 */
export const submitAction = function (vue, action, dicts, success = null, error = null, submit = false, title = "确定？") {
    if (submit) {
        vue.$createDialog({
            type: "confirm",
            icon: "cubeic-alert",
            title: title,
            confirmBtn: { text: "确定", active: true, disabled: false, href: "javascript:;" },
            cancelBtn: { text: "取消" },
            onConfirm: () => {
                saveAction(vue, action, dicts, success, error);
            }
        }).show();
    } else {
        saveAction(vue, action, dicts, success, error);
    }
};
/* 保存 */
export const saveAction = function (vue, action, dicts, success = null, error = null) {
    let toast = vue.$createToast({ txt: "正在保存...", mask: true, time: 0, });
    toast.show();
    var params = { userToken: { userId: vue.$store.state.user.userId } };
    Object.keys(dicts).forEach(c => {
        params[c] = dicts[c];
    });
    action(params).then((res) => {
        toast.hide();
        if (res.code === 0) {
            toast = vue.$createToast({
                txt: "保存成功",
                type: "correct",
                time: 1000,
                onTimeout: () => {
                    if (success) {
                        success();
                    }
                },
            });
            toast.show();
        } else {
            toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
            toast.show();
            if (error) {
                error();
            }
        }
    }).catch((err) => {
        toast.hide();
        toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
        toast.show();
        if (error) {
            error();
        }
    });
};
/* 保存 */
export const saveAttach = function (vue, action, dicts, success = null, error = null) {
    let toast = vue.$createToast({ txt: "正在保存...", mask: true, time: 0, });
    toast.show();
    var params = { userToken: { userId: vue.$store.state.user.userId } };
    Object.keys(dicts).forEach(c => {
        params[c] = dicts[c];
    });
    action(params).then((res) => {
        toast.hide();
        if (res.code === 0) {
            toast = vue.$createToast({
                txt: "保存成功",
                type: "correct",
                time: 1000,
                onTimeout: () => {
                    if (success) {
                        success();
                    }
                },
            });
            toast.show();
        } else {
            toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
            toast.show();
            if (error) {
                error();
            }
        }
    }).catch((err) => {
        toast.hide();
        toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
        toast.show();
        if (error) {
            error();
        }
    });
};
/* 审核 */
export const auditAction = function (vue, action, id, pass, memo, success = null, error = null, objExt = null) {
    vue.dialog = vue.$createDialog({
        type: "prompt",
        title: "请输入意见",
        prompt: { value: memo, placeholder: "请输入意见", },
        onConfirm: (e, promptValue) => {
            if (!promptValue || promptValue == "") {
                let toastAlter = vue.$createToast({ txt: "请输入审核意见", type: "txt", });
                toastAlter.show();
                return false;
            }
            let toast = vue.$createToast({ txt: "正在审核...", mask: true, time: 0, });
            toast.show();
            // 入参
            var params = { pass: pass, memo: promptValue, orderId: id, objExt: objExt, userToken: { userId: vue.$store.state.user.userId } };
            // 提交
            action(params).then((res) => {
                toast.hide();
                if (res.code === 0) {
                    toast = vue.$createToast({
                        txt: "审核成功",
                        type: "correct",
                        time: 1000,
                        onTimeout: () => {
                            if (success) {
                                success();
                            }
                            if (window.history.length <= 1) {
                                vue.$router.replace({ name: "mTaskulous" });
                            } else {
                                vue.$router.go(-1);
                            }
                        },
                    });
                    toast.show();
                } else {
                    toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
                    toast.show();
                    if (error) {
                        error();
                    }
                }
            }).catch((err) => {
                toast.hide();
                toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
                toast.show();
                if (error) {
                    error();
                }
            });
        },
    }).show();
};
/* 撤回 */
export const cancelAction = function (vue, action, id, success = null, error = null) {
    vue.dialog = vue.$createDialog({
        type: "prompt",
        title: "请输入撤回原因",
        prompt: {
            value: "",
            placeholder: "请输入撤回原因",
        },
        onConfirm: (e, promptValue) => {
            if (!promptValue || promptValue == "") {
                let toastAlter = vue.$createToast({ txt: "请输入撤回原因", type: "txt", });
                toastAlter.show();
                return false;
            }
            let toast = vue.$createToast({ txt: "正在撤回...", mask: true, time: 0, });
            toast.show();
            action({ memo: promptValue, orderId: id, userToken: { userId: vue.$store.state.user.userId, }, }).then((res) => {
                toast.hide();
                if (res.code === 0) {
                    toast = vue.$createToast({
                        txt: "撤回成功",
                        type: "correct",
                        time: 1000,
                        onTimeout: () => {
                            if (success) {
                                success();
                            }
                            if (window.history.length <= 1) {
                                vue.$router.replace({ name: "mTaskulous" });
                            } else {
                                vue.$router.go(-1);
                            }
                        },
                    });
                    toast.show();
                } else {
                    toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
                    toast.show();
                    if (error) {
                        error();
                    }
                }
            }).catch((err) => {
                toast.hide();
                toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
                toast.show();
                if (error) {
                    error();
                }
            });
        },
    }).show();
};
/* 操作 */
export const promptAction = function (vue, action, dicts, title, success = null, error = null) {
    vue.dialog = vue.$createDialog({
        type: "prompt",
        title: title,
        prompt: {
            value: "",
            placeholder: title,
        },
        onConfirm: (e, promptValue) => {
            if (!promptValue || promptValue == "") {
                let toastAlter = vue.$createToast({ txt: title, type: "txt", });
                toastAlter.show();
                return false;
            }
            let toast = vue.$createToast({ txt: "正在保存...", mask: true, time: 0, });
            toast.show();
            var params = { memo: promptValue, userToken: { userId: vue.$store.state.user.userId } };
            Object.keys(dicts).forEach(c => {
                params[c] = dicts[c];
            });
            action(params).then((res) => {
                toast.hide();
                if (res.code === 0) {
                    toast = vue.$createToast({
                        txt: "保存成功",
                        type: "correct",
                        time: 1000,
                        onTimeout: () => {
                            if (success) {
                                success();
                            }
                        },
                    });
                    toast.show();
                } else {
                    toast = vue.$createToast({ txt: res.message, type: "txt", time: 3000, });
                    toast.show();
                    if (error) {
                        error();
                    }
                }
            }).catch((err) => {
                toast.hide();
                toast = vue.$createToast({ txt: err, type: "txt", time: 3000, });
                toast.show();
                if (error) {
                    error();
                }
            });
        },
    }).show();
};

/* 加载数据字典 */
export const loadDataOptionAction = function (vue, key, callback, isCode = false) {
    GetDatadictionary({ key: key, userToken: { userId: vue.$store.state.user.userId, } }).then((res) => {
        if (res.code === 0) {
            var dicts = res.data.map(item => {
                return {
                    text: item.name,
                    value: isCode ? item.code : item.name
                }
            })
            callback(dicts);
        } else {
            console.log(res.message);
        }
    }).catch((err) => {
        console.log(err);
    });
};
/* 加载级联数据字典 */
export const loadDataChildOptionAction = function (vue, key, callback) {
    GetDataChildOptions({ key: key, userToken: { userId: vue.$store.state.user.userId, } }).then((res) => {
        if (res.code === 0) {
            callback(res.data);
        } else {
            console.log(res.message);
        }
    }).catch((err) => {
        console.log(err);
    });
};
/* 加载客户信息 */
export const loadCustomerOptionAction = function (vue, key, callback) {
    GetCustomerOptions({ key: key, userToken: { userId: vue.$store.state.user.userId, } }).then((res) => {
        if (res.code === 0) {
            callback(res.data);
        } else {
            console.log(res.message);
        }
    }).catch((err) => {
        console.log(err);
    });
};
/* 加载项目商机信息 */
export const loadProjectOptionAction = function (vue, key, callback) {
    GetProjectOptions({ key: key, userToken: { userId: vue.$store.state.user.userId, } }).then((res) => {
        if (res.code === 0) {
            callback(res.data);
        } else {
            console.log(res.message);
        }
    }).catch((err) => {
        console.log(err);
    });
};
/* 加载用户信息 */
export const loadUserOptionAction = function (vue, key, callback) {
    GetUser({ Keyword: key, page: { pageIndex: 1, pageSize: 1000 }, userToken: { userId: vue.$store.state.user.userId, } }).then((res) => {
        if (res.code === 0) {
            var dicts = res.data.list.map(item => {
                return {
                    id: item.id,
                    name: item.realName
                }
            })
            callback(dicts);
        } else {
            console.log(res.message);
        }
    }).catch((err) => {
        console.log(err);
    });
};
/* 批量上传附件 */
export const filesAddedAction = function (vue, files) {
    let hasIgnore = false
    const maxSize = 10 * 1024 * 1024 // 10M
    for (let k in files) {
        const file = files[k]
        if (file.size > maxSize) {
            file.ignore = true
            hasIgnore = true
        }
    }
    hasIgnore && vue.$createToast({
        type: 'warn',
        time: 1000,
        txt: '单个附件最大限制10M'
    }).show();
};