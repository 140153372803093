/* 通用状态（默认4个，支持扩展） */
export const commonState = function (state, adds) {
    var dic = { 1: "草稿", 2: "待审核", 3: "已拒绝", 4: "已审核" };
    if (adds) {
        Object.keys(adds).forEach(key => {
            dic[key] = adds[key]
        });
    }
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 销售单状态 */
export const saleState = function (state) {
    var dic = { 1: "草稿", 2: "待审核", 3: "已拒绝", 4: "待发货", 5: "部分发货", 6: "已发货" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 询价单状态 */
export const inquiryState = function (state) {
    var dic = { 1: "草稿", 2: "询价中", 3: "部分询价", 4: "已询价", 5: "已生成订单" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 采购单状态 */
export const purchaseState = function (state) {
    var dic = { 1: "草稿", 2: "待审核", 3: "已拒绝", 4: "待收货", 6: "部分入库", 7: "已入库" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 合同状态 */
export const projectState = function (state) {
    var dic = { 1: "草稿", 2: "待审核", 3: "已拒绝", 4: "已审核", 5: "已冻结", 6: "已作废", 10: "待询价", 11: "已撤回", 12: "待审核", 13: "已拒绝", 14: "已审核" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 任务状态 */
export const taskState = function (state) {
    var dic = { 0: "未开始", 1: "进行中", 2: "已完成待确认", 3: "已确认完成", 4: "已关闭", 5: "已取消" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 销项票状态 */
export const invoiceState = function (state) {
    var dic = { 1: "待审核", 2: "已审核", 3: "已开票", 4: "已回款" };
    if (state in dic) {
        return dic[state];
    }
    return state;
};
/* 项目阶段 */
export const projectStep = function (step) {
    var dic = { 0: "已关闭", 1: "商机跟进", 2: "需求确认", 3: "方案汇报", 4: "招投标", 5: "转合同", 6: "项目实施", 7: "验收", 8: "总结", 9: "已冻结" };
    if (step in dic) {
        return dic[step];
    }
    return step;
};